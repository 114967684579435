<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="用户名">
          <el-input
            placeholder="请输入用户名"
            v-model="queryParams.username"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            placeholder="请输入手机号"
            v-model="queryParams.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            v-model="queryParams.role"
            placeholder="请选择角色"
            clearable
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in options"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <el-select
            v-model="queryParams.is_enable"
            placeholder="请选择状态"
            clearable
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in enables"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryRoleList" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="addDialog = true"
            >添加管理员</el-button
          >
        </el-form-item>
      </el-form>
      <el-table :data="roleList" stripe style="width: 100%" border>
        <el-table-column
          type="selection"
          width="55px"
          align="center"
        ></el-table-column>
        <el-table-column prop="id" label="ID" align="center" width="60px">
        </el-table-column>
        <el-table-column prop="username" label="用户名" align="center">
        </el-table-column>

        <el-table-column prop="phone" label="手机" align="center">
        </el-table-column>
        <el-table-column prop="address" label="头像" align="center">
          <template slot-scope="scope">
            <div class="block">
              <el-avatar
                shape="square"
                :size="40"
                :src="
                  scope.row.avatar == ''
                    ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
                    : scope.row.avatar
                "
              ></el-avatar>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="身份" align="center">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.role == 1">
              管理员
            </el-tag>
            <el-tag type="text" v-else-if="scope.row.role == 2"
              >主管
            </el-tag>
            <el-tag type="warning" v-else-if="scope.row.role == 3"
              >销售
            </el-tag>
            <el-tag type="success" v-else-if="scope.row.role == 4"
              >日语老师
            </el-tag>
            <el-tag type="success" v-else-if="scope.row.role == 5"
              >韩语老师
            </el-tag>
            <el-tag type="success" v-else-if="scope.row.role == 6"
              >法语老师
            </el-tag>
            <el-tag type="success" v-else-if="scope.row.role == 7"
              >西语老师
            </el-tag>
            <el-tag type="success" v-else-if="scope.row.role == 8"
              >德语老师
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.is_enable == 1"
              @click="editEnable(scope.row.id, 0)"
            >
              启用
            </el-tag>
            <el-tag type="danger" v-else @click="editEnable(scope.row.id, 1)"
              >禁用
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="login_ip" label="登录IP" align="center">
        </el-table-column>
        <el-table-column prop="login_time" label="最后登录时间" align="center">
          <template slot-scope="scope">
            {{
              $moment(scope.row.login_time * 1000).format("YYYY-MM-DD HH:mm:ss")
            }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" align="center">
          <template slot-scope="scope">
            {{
              $moment(scope.row.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-tooltip content="编辑信息" placement="bottom" effect="dark">
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="editRole(scope.row)"
                size="small"
                circle
              ></el-button>
            </el-tooltip>
            <el-tooltip content="修改密码" placement="top" effect="dark">
              <el-button
                type="warning"
                icon="el-icon-edit"
                @click="editPassword(scope.row.id)"
                size="small"
                circle
              ></el-button>
            </el-tooltip>
            <el-tooltip content="删除管理员" placement="bottom" effect="dark">
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="reomveRole(scope.row.id)"
                size="small"
                circle
              ></el-button>
            </el-tooltip>
            <el-button
                type="success"
                icon="el-icon-info"
                @click="toViewDetails(scope.row)"
                size="small"
                circle
              ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryParams.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>
    </el-card>

      <!-- 详情弹框 -->
     <el-dialog title="详情" :visible.sync="detailsDialog" width="50%">
       <el-form :model="detailsData" :rules="rules" ref="detailsData">
        <el-form-item label="用户头像：" label-width="100px">
          <img :src="detailsData.avatar" alt="" style="width: 180px;height: 180px;">
        </el-form-item>
        <el-form-item label="Id：" label-width="100px">
          <el-input :value="detailsData.id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户名：" label-width="100px">
          <el-input :value="detailsData.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" label-width="100px">
          <el-input :value="detailsData.phone" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="身份：" label-width="100px">
          <el-tag type="danger" v-if="detailsData.role == 1">
              管理员
            </el-tag>
            <el-tag type="text" v-else-if="detailsData.role == 2"
              >主管
            </el-tag>
            <el-tag type="warning" v-else-if="detailsData.role == 3"
              >销售
            </el-tag>
            <el-tag type="success" v-else-if="detailsData.role == 4"
              >日语老师
            </el-tag>
            <el-tag type="success" v-else-if="detailsData.role == 5"
              >韩语老师
            </el-tag>
            <el-tag type="success" v-else-if="detailsData.role == 6"
              >法语老师
            </el-tag>
            <el-tag type="success" v-else-if="detailsData.role == 7"
              >西语老师
            </el-tag>
            <el-tag type="success" v-else-if="detailsData.role == 8"
              >德语老师
            </el-tag>
        </el-form-item>
        <el-form-item label="状态：" label-width="100px">
          <el-tag
              type="success"
              v-if="detailsData.is_enable == 1"
            >
              启用
            </el-tag>
            <el-tag type="danger" v-else>禁用
            </el-tag>
        </el-form-item>
        <el-form-item label="最后登录Ip：" label-width="100px">
          {{detailsData.login_ip}}
        </el-form-item>
        <el-form-item label="最后登录时间:" label-width="100px">
          {{
              $moment(detailsData.login_time * 1000).format("YYYY-MM-DD HH:mm:ss")
            }}
        </el-form-item>
        <el-form-item label="描述：" label-width="100px">
          <el-input type="textarea" :autosize="{minRows: 8}" :value="detailsData.desc" ></el-input>
        </el-form-item>
      </el-form>
     </el-dialog>
    <!-- 修改信息弹框 -->
    <el-dialog title="修改信息" :visible.sync="editDialog" width="30%">
      <el-form :model="editForm" :rules="rules" ref="editForm">
        <el-form-item label="用户名" label-width="100px" prop="username">
          <el-input v-model="editForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" label-width="100px" prop="phone">
          <el-input v-model="editForm.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色" label-width="100px" prop="role" v-if="userInfo.role == 1">
          <el-select v-model="editForm.role" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" label-width="100px" prop="phone">
          <el-input type="textarea" :autosize="{minRows: 8}" v-model="editForm.desc" ></el-input>
        </el-form-item>
        <el-form-item label-width="40%">
          <el-button type="primary" @click="editNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 添加管理员弹框 -->
    <el-dialog title="添加管理员" :visible.sync="addDialog" width="30%" @close="closed">
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-form-item label="用户名" label-width="100px" prop="username">
          <el-input v-model="addForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" label-width="100px" prop="phone">
          <el-input v-model="addForm.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="100px" prop="password">
          <el-input v-model="addForm.password" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色" label-width="100px" prop="role">
          <el-select v-model="addForm.role" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="描述" label-width="100px" prop="password">
          <el-input type="textarea" :autosize="{minRows: 8}" v-model="addForm.desc" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label-width="40%">
          <el-button type="primary" @click="addRole">立即添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 修改密码弹框 -->
    <el-dialog title="修改密码" :visible.sync="editPasswordDialog" width="30%">
      <el-form :model="editPasswordForm" :rules="rules" ref="editPasswordForm">
        <el-form-item label="原密码" label-width="100px" prop="old_password">
          <el-input
            v-model="editPasswordForm.old_password"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" label-width="100px" prop="new_password">
          <el-input
            v-model="editPasswordForm.new_password"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" label-width="100px" prop="rep_password">
          <el-input
            v-model="editPasswordForm.rep_password"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="40%">
          <el-button type="primary" @click="editPasswordNow"
            >修改密码</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { warningDialog } from "@/utils/dialog";
import {
  getRole,
  editRoles,
  removeRoles,
  addRoles,
  editPasswords,
  editEnables
} from "@/api/rolelist.js";
import { getInfo } from "@/api/user";
import { log } from "util";
export default {
  data() {
    const phonerule = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    const passwordR = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.editPasswordForm.new_password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      roleList: [],
      value: true,
      editDialog: false,
      editForm: {
        username: "",
        phone: "",
        id: "",
        desc: "",
        role: ""
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 1, max: 8, message: "长度在 1 到 8 个字符", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: phonerule,
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 5, max: 20, message: "密码长度应在5-20位", trigger: "blur" }
        ],
        role: [
          { required: true, message: "请选择创建的角色", trigger: "blur" }
        ],
        old_password: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          { min: 5, max: 20, message: "密码长度应在5-20位", trigger: "blur" }
        ],
        new_password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 5, max: 20, message: "密码长度应在5-20位", trigger: "blur" }
        ],
        rep_password: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          {
            validator: passwordR,
            trigger: "blur"
          }
        ]
      },
      queryParams: {
        username: "",
        start_time: 0,
        end_time: 0,
        phone: "",
        role: null,
        is_enable: null,
        page:1,
        limit:10,
      },
      total: 0,
      addDialog: false,
      addForm: {
        username: "",
        phone: "",
        password: "",
        role: ""
      },
      options: [
        {
          value: 1,
          label: "管理员"
        },
        {
          value: 2,
          label: "主管"
        },
        {
          value: 3,
          label: "销售"
        },
        {
          value: 4,
          label: "日语老师"
        },
        {
          value: 5,
          label: "韩语老师"
        },
        {
          value: 6,
          label: "法语老师"
        },
        {
          value: 7,
          label: "西语老师"
        },
        {
          value: 8,
          label: "德语老师"
        },
      ],
      enables: [
        {
          value: 1,
          label: "启用"
        },
        {
          value: 0,
          label: "禁用"
        }
      ],
      editPasswordDialog: false,
      editPasswordForm: {
        id: "",
        old_password: "",
        new_password: "",
        rep_password: ""
      },
      detailsDialog:false, //详情弹窗
      detailsData:{}, //详情
      userInfo: {}
    };
  },
  methods: {
    getUserInfo() {
      getInfo().then(res => {
        this.userInfo = res.data;
      });
    },
    getRoleList() {
      getRole(this.queryParams).then(res => {
        this.roleList = res.data.data;
        this.total = res.data.total;
      });
    },
    // 点击修改图标
    editRole(item) {
      this.getUserInfo()
      this.editForm.id = item.id;
      this.editForm.username = item.username;
      this.editForm.phone = item.phone;
      this.editForm.desc  =item.desc;
      this.editForm.role  =item.role;
      this.editDialog = true;
    },
    // 点击立即修改按钮
    editNow() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          editRoles(this.editForm).then(res => {
            if (res.code !== 1) return this.$message.error("修改失败,请重试");
            this.$message.success("修改信息成功");
            this.editDialog = false;
            this.getRoleList();
          });
        } else {
          return false;
        }
      });
    },
    async reomveRole(id) {
      const result = await this.$confirm("是否要删除该用户?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      removeRoles(id).then(res => {
        if (res.code !== 1) {
          this.$message.error("删除用户失败");
        }
        this.$message.success("删除用户成功");
        this.getRoleList();
      });
    },
    // 添加管理员
    addRole() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          addRoles(this.addForm).then(res => {
            if (res.code !== 1){
               this.$message.error("添加管理员失败")
               this.addDialog = false;
               return
            }
            this.$message.success("添加管理员成功");
            this.addDialog = false;
            this.getRoleList();
          });
        } else {
          return false;
        }
      });
    },
    closed(){
       this.$refs.addForm.resetFields()
    },
    editPassword(id) {
      this.editPasswordForm.id = id;
      this.editPasswordDialog = true;
    },
    editPasswordNow() {
      // console.log(this.editPasswordForm);
      this.$refs.editPasswordForm.validate(valid => {
        if (valid) {
          editPasswords(this.editPasswordForm).then(res => {
            if (res.code !== 1) return this.$message.error("修改密码失败");
            this.$message.success("修改密码成功");
            this.editPasswordDialog = false;
            this.getRoleList();
          });
        } else {
          return false;
        }
      });
    },
    queryRoleList() {
      this.queryParams.page=1
      this.getRoleList();
    },
    // 用户禁用和启用状态
    editEnable(id, target) {
      if (target === 0) {
        // console.log("禁用");
        warningDialog("确定要禁用吗？").then(()=>{
          editEnables({
            id: id,
            enable: target
          }).then(res => {
            if(res==undefined){
              return
            }
            this.$message.warning("禁用成功");
            this.getRoleList();
          });
        })

      } else {
        warningDialog("确定要启用吗？").then(()=>{
          editEnables({
            id: id,
            enable: target
          }).then(res => {
            if(res==undefined){
              return
            }
            this.$message.success("启用成功");
            this.getRoleList();
          });
        })

      }
    },
    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.getRoleList()
    },
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.getRoleList()
    },

    // 查看详情
    toViewDetails(row){
      this.detailsData=row
      this.detailsDialog=true
    }
  },
  created() {
    this.getRoleList();
  }
};
</script>

<style scoped>
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
}
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .el-pager li.active{
  color: #FF6688;
}
</style>
