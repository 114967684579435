import request from '@/utils/request'

export function login(data) {
  // const dak='phone=13265011932&password=admin'
  return request({
    url: '/admin/base/rUserLogin',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/admin/base/rUserInfo',
    method: 'get',
    data:token
  })
}

export function logout() {
  return request({
    url: '/',
    method: 'post'
  })
}
